/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {PageProps} from "gatsby"
import HTMLReactParser from "html-react-parser"

import {macroReplacer} from "../transformers"

import {Page} from "../../types"

import {Theme} from "../styles/theme"

import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import SingleBanner from "../components/single-banner"

const PageTemplate = (page: PageProps): JSX.Element => {
  const data = page.pageContext as Page

  return (
    <Layout title={data.title} currentPage={data}>
      {data.header.length > 0 && (
        <SingleBanner
          bannerTitle={data.header[0].bannerTitle}
          bannerImage={{url: data.header[0].bannerImage.url}}
          size="small"
        />
      )}

      <div css={PlainPageStyles}>
        <Sidebar currentPage={data}>
          <h3>{data.title}</h3>
          <div>
            {HTMLReactParser(data.content, {
              replace: object => macroReplacer(data, object),
            })}
          </div>
        </Sidebar>
      </div>
    </Layout>
  )
}

export default PageTemplate

const PlainPageStyles = css`
  margin-top: 8rem;

  @media ${Theme.breakpoints.mediumDown} {
    margin-top: 2rem;
  }
`
